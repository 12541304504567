import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import PhotographyTabItem from "./PhotographyTabItem";
// TODO : import this, make it not static, make it dynamic, it should be read from aws
const tabList = ["All", "Photo Stories", "Test Shoots", "Studio", "Fashion", "Street Photography"];

const photoStories = [
  {
    img: "1",
    title: "A West London Family",
    subTitle: "Interview",
    alterText: "A West London Family",
    portfolioBannerLink: "/img/portfolio/photostories/awestlondonfamily/1.jpg",
    portfolioLink: "/portfolio/photostories/awestlondonfamily"
  },
  {
    img: "2",
    title: "Herrana",
    subTitle: "Interview",
    alterText: "A West London Family",
    portfolioBannerLink: "/img/portfolio/photostories/herrana/1.jpg",
    portfolioLink: "/portfolio/photostories/herrana"
  },
  {
    img: "3",
    title: "Ade Ori",
    subTitle: "Photo Story",
    alterText: "Ade Ori",
    portfolioBannerLink: "/img/portfolio/photostories/adeori/1.jpg",
    portfolioLink: "/portfolio/photostories/adeori"
  }
];

const testShoots = [
  {
    img: "1",
    title: "Mopesola",
    subTitle: "Model",
    alterText: "Mopesola",
    portfolioBannerLink: "/img/portfolio/testshoots/mo/1.jpg",
    portfolioLink: "/portfolio/testshoots/mo/"
  },
  {
    img: "2",
    title: "Nyally",
    subTitle: "Model",
    alterText: "Nyally",
    portfolioBannerLink: "/img/portfolio/testshoots/nyally/1.jpg",
    portfolioLink: "/portfolio/testshoots/nyally/"
  },
  {
    img: "3",
    title: "Rebecca",
    subTitle: "Model",
    alterText: "Rebecca",
    portfolioBannerLink: "/img/portfolio/testshoots/rebecca/1.jpg",
    portfolioLink: "/portfolio/testshoots/rebecca/"
  },
  {
    img: "4",
    title: "Julian",
    subTitle: "Model",
    alterText: "Julian",
    portfolioBannerLink: "/img/portfolio/testshoots/julian/1.jpg",
    portfolioLink: "/portfolio/testshoots/julian"
  }
];

const fashionShoot = [
  {
    img: "1",
    title: "Hakim",
    subTitle: "Fashion Shoot",
    alterText: "Portrait",
    portfolioBannerLink: "/img/portfolio/fashionshoot/hakim/1.jpg",
    portfolioLink:"/portfolio/fashionshoot/hakim"
  },
  {
    img: "2",
    title: "Homecoming",
    subTitle: "Fashion Shoot",
    alterText: "A West London Family",
    portfolioBannerLink: "/img/portfolio/photostories/homecoming/1.jpg",
    portfolioLink: "/portfolio/fashionshoot/homecoming"
  },
  {
    img: "3",
    title: "Shaquille",
    subTitle: "Fashion Shoot",
    alterText: "Portrait",
    portfolioBannerLink: "/img/portfolio/fashionshoot/shaquille/1.jpg",
    portfolioLink: "/portfolio/fashionshoot/shaquille"
  },
  {
    img: "4",
    title: "Maty",
    subTitle: "Fashion Shoot",
    alterText: "Portrait",
    portfolioBannerLink: "/img/portfolio/fashionshoot/maty/1.jpg",
    portfolioLink: "/portfolio/fashionshoot/maty"
  },
  {
    img: "5",
    title: "Taylor",
    subTitle: "Fashion Shoot",
    alterText: "Portrait",
    portfolioBannerLink: "/img/portfolio/fashionshoot/taylor/1.jpg",
    portfolioLink: "/portfolio/fashionshoot/taylor"
  },
  {
    img: "6",
    title: "Carmen's Line",
    subTitle: "Fashion Shoot",
    alterText: "Fashion Shoot",
    portfolioBannerLink: "/img/portfolio/fashionshoot/carmen/1.jpg",
    portfolioLink: "/portfolio/fashionshoot/carmen/"
  }
]

const streetPhotography = [
  {
    img: "1",
    title: "Nigeria",
    subTitle: "Street Photography",
    alterText: "Street Photography & Landscape",
    portfolioBannerLink: "/img/portfolio/streetphotography/nigeria/1.jpg",
    portfolioLink: "/portfolio/streetphotography/nigeria"
  },
  {
    img: "1",
    title: "Dominica Republic",
    subTitle: "Street Photography",
    alterText: "Street Photography & Landscape",
    portfolioBannerLink: "/img/portfolio/streetphotography/dominicarepublic/1.jpg",
    portfolioLink: "/portfolio/streetphotography/dominicarepublic"
  }
]

const studioPhotography = [
  {
    img: "1",
    title: "Kedean",
    subTitle: "Model",
    alterText: "Studio",
    portfolioBannerLink: "/img/portfolio/studio/kedean/1.jpg",
    portfolioLink: "/portfolio/studio/kedean"
  }
]

const allPhotos = [];

const addItemsToAllPhotos = (photoArray) => {
  // eslint-disable-next-line array-callback-return
  photoArray.map(item => {
    allPhotos.push(item);
  });
};

addItemsToAllPhotos(photoStories);
addItemsToAllPhotos(studioPhotography);
addItemsToAllPhotos(testShoots);
addItemsToAllPhotos(fashionShoot);
addItemsToAllPhotos(streetPhotography);


const Photography = () => {
  return (
    <SimpleReactLightbox>
      <div className="positon-relative">
        <div className="portfolio-filter-01">
          <Tabs>
            <TabList className="filter d-flex justify-content-center">
              {tabList.map((val, i) => (
                <Tab key={i}>{val}</Tab>
              ))}
            </TabList>
            {/* End tablist */}

            <SRLWrapper>
              <TabPanel>
                <PhotographyTabItem tabItems={allPhotos}/>
              </TabPanel>
              <TabPanel>
                <PhotographyTabItem tabItems={photoStories}/>
              </TabPanel>
              <TabPanel>
                <PhotographyTabItem tabItems={testShoots}/>
              </TabPanel>
              <TabPanel>
                <PhotographyTabItem tabItems={studioPhotography}/>
              </TabPanel>
              <TabPanel>
                <PhotographyTabItem tabItems={fashionShoot}/>
              </TabPanel>
              <TabPanel>
                <PhotographyTabItem tabItems={streetPhotography}/>
              </TabPanel>
            </SRLWrapper>
            {/* End tabpanel */}
          </Tabs>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default Photography;
